import React from "react";
import "./uiUtilities.css";

export class UiUtilities extends React.Component {
    render() {
        let content = <div className="ui-utilitiesy" ></div>
        if (this.props) {
            if (this.props.utilityName === 'customloader')
                content = <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            if (this.props.utilityName === 'loader')    
                content = <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
        return <div className="ui-utilities" >{content}</div>;
  }
}