import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ZIndexContext } from "@progress/kendo-react-common";
import { Col, Form, Row } from "react-bootstrap";

export const FsDropDownList = ({
  data,
  textField,
  dataItemKey,
  onChange,
  defaultItem,
  value,
  disabled,
  style,
  inline,
  className,
  labelCol,
  controlCol,
  label,
}) => {
  return (
    <>
      <ZIndexContext.Provider
        value={100003}
        style={disabled ? { cursor: "not-allowed !important" } : {}}
      >
        {" "}
        {inline ? (
          <>
            <Form.Group as={Row} className={className ?? ""}>
              <Form.Label column sm={labelCol}>
                {label}
              </Form.Label>
              <Col sm={controlCol}>
                <DropDownList
                  style={style}
                  data={data}
                  textField={textField}
                  dataItemKey={dataItemKey}
                  onChange={onChange}
                  defaultItem={defaultItem}
                  value={value}
                />
              </Col>
            </Form.Group>
          </>
        ) : (
          <DropDownList
            style={style}
            data={data}
            textField={textField}
            dataItemKey={dataItemKey}
            onChange={onChange}
            defaultItem={defaultItem}
            value={value}
          />
        )}
      </ZIndexContext.Provider>
    </>
  );
};
