import React, { Component } from 'react';
import { FsapiMap } from './fsapiMap/fsapiMap';

export class Home extends Component {
    static displayName = Home.name;
    render() {
    return (
        <div>
            <FsapiMap />
      </div>
    );
  }
}
