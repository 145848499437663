import authService from "./../components/api-authorization/AuthorizeService";
import { GET } from "./HttpClientService";

export const currentLangKey = "currentLang";
export const defaultLang = "en";
export const langObjectKey = "languageArray";
export const shouldReloadLangKey = "reloadLang";

const getGlobalizationObject = async (culture) => {
  var isAuthenticated = await authService.isAuthenticated();
  if (isAuthenticated) {
    var res = await GET(`api/globalization/${culture}`);
    if (res.success) return res.data;
  }
  return "";
};
const shouldCallApi = () => {
  var currentLang = getSelectedLang();
  //case 1: if no language has been previously selected
  //return true this will result in the call of api
  //and ONLY SET default language
  if (currentLang == null) {
    return true;
  }
  //case 2: selected lang exists check if should reload
  //then DO NOT set true as it will get set once API call
  //is completed and only return true to run API call
  var shouldReload = localStorage.getItem(shouldReloadLangKey);
  if (shouldReload === null || shouldReload === "true") return true;

  //if both values found and should'nt reload
  //do NOT call API
  return false;
};
const setSelectedLang = (culture, shouldReload) => {
  //update timestamp for selecting a new lang
  localStorage.setItem(shouldReloadLangKey, shouldReload);
  localStorage.setItem(currentLangKey, culture);
};
const getSelectedLang = () => {
  var currentCulture = localStorage.getItem(currentLangKey);
  return currentCulture ?? defaultLang;
};

//this method is what needs to be called when app loads
export const loadCurrentLanguage = async () => {
  if (shouldCallApi()) {
    var currentLang = getSelectedLang();
    var langObj = await getGlobalizationObject(currentLang);
    localStorage.setItem(langObjectKey, JSON.stringify(langObj));
    setSelectedLang(currentLang, false);
  }
};

export const storeSelectedLanguage = async (culture) => {
  setSelectedLang(culture, true);
  await loadCurrentLanguage();
};

//use this to populate languages dropdown
export const getLanguagesList = async () => {
  var isAuthenticated = await authService.isAuthenticated();
  if (isAuthenticated) {
    var res = await GET(`api/globalization/Langs`);
    if (res.success) return res.data;
  }
  return "";
};
export const getSingleValue = async (key) => {
  var isAuthenticated = await authService.isAuthenticated();
  var currentCulture = getSelectedLang();
  if (isAuthenticated) {
    var res = await GET(`api/globalization/Value/${currentCulture}/${key}`);
    if (res.success) return res.data;
  }
  return "";
};
