import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import authService from "../components/api-authorization/AuthorizeService";
import { GET } from "./HttpClientService";
import { FsLabel } from "../components/FsApiUi/FsLabel";
export const GetAdminMenuDefs = async () => {
  const email = await authService.getUserEmail();
  const res = GET("api/menu/" + email);
  return res;
};

//define all site menus here
//ONLY MAIN SITE NAVIGATION. ADMIN MENU AND OTHER ROLE BASED MENU ITEMS
//WILL COME FROM Api/Menu/{email}
export const MenuDefs = {
  SelectMap: [
    {
      name: (
        <FsLabel
          textKey="MainMenu_Organization_Views_Label"
          replaceIfEmpty="ORGANIZATION VIEW"
        />
      ),
      path: "",
    },
    {
      name: (
        <FsLabel
          textKey="MainMenu_Personal_Views_Label"
          replaceIfEmpty="PERSONAL VIEW"
        />
      ),
      path: "",
    },
    {
      name: (
        <FsLabel textKey="MainMenu_Options_Label" replaceIfEmpty="OPTIONS" />
      ),
      path: "",
    },
  ],

  ACTIVATE: [
    {
      name: (
        <FsLabel textKey="MainMenu_Hot_Keys_Label" replaceIfEmpty="Hot Keys" />
      ),
      path: "",
    },
    {
      name: (
        <FsLabel
          textKey="MainMenu_Manual_Activation_Label"
          replaceIfEmpty="Manual Activations"
        />
      ),
      path: "",
    },
    {
      name: <FsLabel textKey="MainMenu_Macros_Label" replaceIfEmpty="Macros" />,
      path: "",
    },
    {
      name: (
        <FsLabel
          textKey="MainMenu_Reload_RTU_Label"
          replaceIfEmpty="Reload RTU (DB)"
        />
      ),
      path: "",
    },
    {
      name: (
        <FsLabel
          textKey="MainMenu_Get_RTU_Label"
          replaceIfEmpty="GET RTU (CCU)"
        />
      ),
      path: "",
    },
    {
      name: (
        <FormGroup check>
          <Label check>
            <Input type="checkbox" /> DEMO MODE
          </Label>
        </FormGroup>
      ),
      path: "",
    },
  ],
  REPORTING: [
    { name: "Reports", path: "" },
    {
      name: (
        <FsLabel
          textKey="UserManagement_Messaging_Analytics_Label"
          replaceIfEmpty="Messaging Analytics"
        />
      ),
      path: "",
    },
    {
      name: <FsLabel textKey="" replaceIfEmpty="Event History" />,
      path: "",
    },
    {
      name: <FsLabel textKey="" replaceIfEmpty="Lightining Event History" />,
      path: "",
    },
    {
      name: <FsLabel textKey="" replaceIfEmpty="PubNub History" />,
      path: "",
    },
  ],
};
