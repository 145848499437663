import { createSlice } from "@reduxjs/toolkit";

const siteSlice = createSlice({
  name: "sites",
  initialState: {
    sites: [],
    currentSite: null,
  },
  reducers: {
    setUserCurrentSite(state, action) {
      state.currentSite = action.payload;
    },
    getUserCurrentSite(state, action) {
      state.currentSite;
    },
    setSites(state, action) {
      state.sites = action.payload;
    },
    getSites(state, action) {
      state.sites;
    },
  },
});
export const actions = siteSlice.actions;
export default siteSlice;
