import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  InputGroup,
  Button,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import "./NavMenu.css";
import { FsMenu } from "./FsApiUi/FsMenu";
import { MenuDefs } from "../services/MenuService";
import { FsIcon } from "./FsApiUi/FsIcon";
import { FsAccountsDropDown } from "./FsApiUi/FsAccountDropDown";
import { FsLabel } from "./FsApiUi/FsLabel";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          <NavbarBrand tag={Link} to="/">
            fsapi_rw
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!this.state.collapsed}
            navbar
          >
            {/* it is critical to set can view to false, when accounts dropdown need to ONLY show for fsc admin
            if read is allowed do not set any attributes and the menu will disable itself automatically if not fscadmin*/}
            <FsAccountsDropDown canView={false} />
            <ul className="navbar-nav flex-grow">
              <FsMenu
                name={
                  <FsLabel
                    textKey="MainMenu_Select_Map_Label"
                    devFriendlyLabel="SELECT MAP"
                    toUpper={true}
                  />
                }
                items={MenuDefs.SelectMap}
              />
              <NavItem>
                <NavLink tag={Link} to="/counter">
                  <FsLabel
                    textKey="MainMenu_Poll_All_Label"
                    devFriendlyLabel="POLL ALL"
                    toUpper={true}
                  />
                </NavLink>
              </NavItem>
              <FsMenu
                name={
                  <FsLabel
                    textKey="MainMenu_Activate_Label"
                    devFriendlyLabel="Activate"
                    toUpper={true}
                  />
                }
                items={MenuDefs.ACTIVATE}
              />
              <FsMenu
                name={
                  <FsLabel
                    textKey="MainMenu_Reports_Label"
                    devFriendlyLabel="Reports"
                    toUpper={true}
                  />
                }
                items={MenuDefs.REPORTING}
              />
              <InputGroup className="width-30">
                <Input className="height-10" />
                <Button color="danger" className="height-10">
                  <FsIcon name="search" />
                </Button>
              </InputGroup>
              <LoginMenu></LoginMenu>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
