import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
///use this class to encapsulate the use
///of reactstrap menu items in one place
///1. you can either pass a single menu item with path
///2. pass an array of items with name and path
///3. pass an array of items where each item contains an array
///NOTE: name and path are type string
///example
// const items = [
//   {
//       name: "test link", path: profilePath, items: [
//           { name: "test lin2", path: profilePath },
//           { name: "test lin2", path: profilePath }
//       ]
//   },
//   { name: "test lin2", path: profilePath },
//   { name: "test lin4", path: profilePath },
// ]
export class FsMenu extends Component {
  getUniqueKey() {
    return parseInt(
      (Math.random() * 100).toFixed() * (Math.random() * 100).toFixed()
    );
  }
  processMenuItems(items, key) {
    if (items && items.constructor.name === "Array") {
      return (
        <DropdownMenu key={this.getUniqueKey()}>
          {items.map((item, key) => (
            <React.Fragment key={this.getUniqueKey()}>
              {this.processMenuItems(item)}
            </React.Fragment>
          ))}
        </DropdownMenu>
      );
    }

    if (items && items.constructor.name === "Object") {
      if (items.items && items.items.constructor.name === "Array") {
        return (
          <UncontrolledDropdown nav inNavbar key={this.getUniqueKey()}>
            <DropdownToggle
              tag="span"
              nav
              caret={items.items ? items.items.length > 0 : false}
              className="right-100"
              key={this.getUniqueKey()}
            >
              {items.name}
            </DropdownToggle>
            {items.items ? this.processMenuItems(items.items) : null}
          </UncontrolledDropdown>
        );
      } else {
        return (
          <React.Fragment key={this.getUniqueKey()}>
            <DropdownItem>
              <NavLink tag={Link} to={items.path}>
                {items.name}
              </NavLink>
            </DropdownItem>
          </React.Fragment>
        );
      }
    }

    return null;
  }

  render() {
    return (
      <>
        {this.props.dividerTop ? <DropdownItem divider /> : <></>}
        <UncontrolledDropdown nav inNavbar key={this.getUniqueKey()}>
          <DropdownToggle
            tag="span"
            nav
            caret={this.props.items ? this.props.items.length > 0 : false}
            key={this.getUniqueKey()}
            className={this.props.linkColor ?? "text-dark"}
          >
            {this.props.name}
          </DropdownToggle>
          {this.props.items ? this.processMenuItems(this.props.items) : <></>}
        </UncontrolledDropdown>
        {this.props.dividerBottom ? (
          <DropdownItem divider key={this.getUniqueKey()} />
        ) : (
          <></>
        )}
      </>
    );
  }
}
