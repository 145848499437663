import React, { Fragment } from "react";
import { useEffect, useState } from "react";

export const FsButton = ({
  label,
  onClick,
  className,
  type = "default",
  children,
}) => {
  const [cont, setCont] = useState("");
  useEffect(() => {
    let c = "btn btn-danger";
    switch (type) {
      case "default":
        c = "btn btn-danger";
        break;
      case "success":
        c = "btn btn-success";
        break;
      case "gray":
        c = "btn btn-secondary";
        break;
      default:
        c = "btn btn-danger";
        break;
    }
    setCont(c);
  }, []);
  ///button types:
  //1. default - Red and white text
  //2. success - Green and white
  //3. gray - Gray and white

  return (
    <>
      {
        <button className={cont + " " + className} onClick={onClick}>
          {children}
        </button>
      }
    </>
  );
};
