import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getLanguagesList,
  langObjectKey,
  loadCurrentLanguage,
  storeSelectedLanguage,
} from "../../services/GlobalizationService";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { actions } from "../../Store/lang-slice";

export const LanguageMenu = () => {
  const [langList, setLangList] = useState([]);
  const dispatch = useDispatch();
  const loadData = async () => {
    await loadCurrentLanguage();
    const langList = await getLanguagesList();
    setLangList(langList);
    dispatchLangToStore();
  };
  const setLanguage = async (culture) => {
    await storeSelectedLanguage(culture);
    dispatchLangToStore();
  };
  const dispatchLangToStore = () => {
    setTimeout(
      () =>
        dispatch(
          actions.setCurrentLang(
            JSON.parse(localStorage.getItem(langObjectKey))
          )
        ),
      200
    );
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle tag="span" className="nav-link" caret>
        Select Language
      </DropdownToggle>
      <DropdownMenu>
        {langList &&
          langList.map((x, ke) => (
            <DropdownItem
              tag="span"
              className="nav-link"
              onClick={() => setLanguage(x)}
              key={ke * 20}
            >
              {x}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
