import { configureStore } from "@reduxjs/toolkit";
import userAccountsSlice from "./accounts-slice";
import userOrgsSlice from "./orgs-slice";
import langSlice from "./lang-slice";
import siteSlice from "./site-slice";
const store = configureStore({
  reducer: {
    accounts: userAccountsSlice.reducer,
    orgs: userOrgsSlice.reducer,
    sites: siteSlice.reducer,
    langs: langSlice.reducer,
  },
});
export default store;
// const initState = {
//     userAccounts: [],
//     currentAccount: null,
//     currentAccountOrgs: [],
//     currentOrg: null,
//     currentLang: []
// }
