import React, { useEffect, useState } from "react";
import { getUserAccounts$ } from "../../services/AccountService";
import { UiUtilities } from "../uiUtilities/uiUtilities";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../Store/accounts-slice";
import authService from "../api-authorization/AuthorizeService";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Col, Form, Row } from "react-bootstrap";
import { ZIndexContext } from "@progress/kendo-react-common";
export const FsAccountsDropDown = ({
  inline,
  labelCol,
  controlCol,
  label,
  className,
  disabled,
  canView = true,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [isFscAdmin, setIsFscAdmin] = React.useState(false);
  const userAccounts = useSelector((state) => state.accounts.userAccounts);
  const localCurrentAccount = useSelector(
    (state) => state.accounts.currentAccount
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getAuthorizedAccounts();
    loadUserData();
  }, []);

  const getAuthorizedAccounts = async () => {
    var loadAccounts = await getUserAccounts$();
    setAccount(loadAccounts);
  };

  const setAccount = (accounts) => {
    dispatch(actions.setUsersAccounts(accounts));
  };

  const loadUserData = async () => {
    let userProfile = await authService.getProfile();
    setIsFscAdmin(userProfile.fscAdmin);
    setLoading(false);
  };

  const setCurrentAccount = (value) => {
    try {
      dispatch(actions.setUserCurrentAccount(value));
    } catch (e) {
      dispatch(actions.setUserCurrentAccount(null));
      return;
    }
  };
  return (
    <>
      {loading ? (
        <UiUtilities utilityName="loader" />
      ) : (
        <ZIndexContext.Provider
          value={100003}
          style={disabled ? { cursor: "not-allowed !important" } : {}}
        >
          {isFscAdmin || canView ? (
            <>
              {inline ? (
                <>
                  <Form.Group as={Row} className={className ?? ""}>
                    <Form.Label column sm={labelCol}>
                      {label}
                    </Form.Label>
                    <Col
                      sm={controlCol}
                      className={disabled || !isFscAdmin ? "k-disabled" : ""}
                    >
                      <DropDownList
                        data={userAccounts}
                        textField="name"
                        dataItemKey="accountId"
                        onChange={(e) => {
                          setCurrentAccount(e.target.value);
                        }}
                        defaultItem={{ name: "Select Account", accountId: 0 }}
                        value={localCurrentAccount}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <>
                  <DropDownList
                    style={{
                      width: "300px",
                    }}
                    data={userAccounts}
                    textField="name"
                    dataItemKey="accountId"
                    onChange={(e) => {
                      setCurrentAccount(e.target.value);
                    }}
                    defaultItem={{ name: "Select Account", accountId: 0 }}
                    value={localCurrentAccount}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </ZIndexContext.Provider>
      )}
    </>
  );
};
