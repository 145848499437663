/* eslint-disable no-undef */
import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { getMapsApiKey } from "../../services/FsApiMapService";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { UiUtilities } from "../uiUtilities/uiUtilities";
function MapProvider(props) {
  const [map, setMap] = React.useState(null);
  var { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBgbLodAt8P7o71My9YlMSjip3XZjjGtTQ",
    mapIds: ["demoMapIds"],
  });

  const configureAndLoadMakerCluster = async (map) => {
    if (props.useClusteredMarkers) {
      drawClusteredMarkers(map);
    } else {
      drawMarkers(map);
    }
    };
  const drawClusteredMarkers = async (map) => {
    await google.maps.importLibrary("maps");
    await google.maps.importLibrary("marker");
    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    let markersToAdd = [];
    props.markers.forEach((mark) => {
      const pinGlyph = new google.maps.marker.PinElement({
        glyph: "", //you can add label here
        glyphColor: "black",
      });
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: mark.position,
        content: pinGlyph.element,
        gmpDraggable: true,
      });

      // markers can only be keyboard focusable when they have click listeners
      // open info window when marker is clicked
      marker.addListener("click", (e) => {
        handleMarkerClick(e);
        infoWindow.setContent(
          marker.title + ":" + mark.position.lat + ", " + mark.position.lng
        );
        infoWindow.open(map, marker);
      });
      markersToAdd.push(marker);
    });
    new MarkerClusterer({ markers: markersToAdd, map: map });
  };
  const drawMarkers = (map) => {
    props.markers.forEach((mark) => {
      let marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        icon: mark.icon,
        position: mark.position,
        map,
        title: mark.title,
        clickable: mark.clickable,
      });
      marker.addListener("click", handleMarkerClick);
    });
  };
  const initMap = React.useCallback(function callback(map) {
    //center map to logged in user center
    const bounds = new window.google.maps.LatLngBounds(props.center);
    map.fitBounds(bounds);
    setTimeout(() => {
      configureAndLoadMakerCluster(map);
    }, 50);
    //adjust zoom with 200ms delay
    setTimeout(() => map.setZoom(props.zoom), 100);
    //set the final map object in the projects state
    setMap(map);
  }, []);

  const handleMarkerClick = (e) => {
    console.log(e);
  };
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      options={{ mapId: "6e120bcd575d29f7" }}
      mapContainerStyle={props.mapContainerStyle}
      onLoad={initMap}
      onUnmount={onUnmount}
    ></GoogleMap>
  ) : (
    <>
      <UiUtilities utilityName="loader" />
    </>
  );
}

export default React.memo(MapProvider);
