import React, { Fragment } from "react";
import {
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { FsIcon } from "../FsApiUi/FsIcon";
import { FsMenu } from "../FsApiUi/FsMenu";
import { FsAdminMenuModaly } from "../fsAdminMenuModal/fsAdminMenuModaly";
import { LanguageMenu } from "../FsApiUi/LanguageMenu";
import { FsLabel } from "../FsApiUi/FsLabel";

export class LoginMenu extends React.Component {
  state = {
    isAuthenticated: false,
    userName: null,
    isFscAdmin: false,
  };

  childCallables = null;

  setChildCallables = (callables) => {
    this.childCallables = callables;
  };

  handleKeyDown = (event) => {
    this.childCallables.doToggle(event);
  };

  async componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user, isFscAdmin] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
      authService.isFcsAdmin(),
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
      isFscAdmin,
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;

    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(
        userName,
        profilePath,
        logoutPath,
        logoutState
      );
    }
  }

  authenticatedView(userName, profilePath, logoutPath, logoutState) {
    const items = [
      {
        name: "test link",
        path: profilePath,
        items: [
          { name: "test lin2", path: profilePath },
          { name: "test lin2", path: profilePath },
        ],
      },
      { name: "test lin2", path: profilePath },
      { name: "test lin4", path: profilePath },
    ];

    return (
      <Fragment>
        <NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
              <FsIcon name="person" iconColor="red" />
            </DropdownToggle>
            <DropdownMenu start="start">
              <FsMenu name="Example menu" items={items} dividerTop />
              <DropdownItem>
                <FsLabel
                  textKey="ProfileMenu_Welcome_Label"
                  devFriendlyLabel="Welcome"
                />
                <NavLink tag={Link} className="text-danger" to={profilePath}>
                  {userName}
                </NavLink>
                <small>
                  <FsLabel
                    textKey="ProfileMenu_Current_Organization_Label"
                    devFriendlyLabel="Current Organization"
                  />
                </small>
              </DropdownItem>
              <LanguageMenu />
              {this.state.isFscAdmin && (
                <>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.handleKeyDown}>
                    <FsLabel
                      textKey="ProfileMenu_Administrative_Settings_Label"
                      devFriendlyLabel="Administrative Settings"
                    />
                  </DropdownItem>
                </>
              )}
              <DropdownItem divider />
              <DropdownItem>
                <FsLabel
                  textKey="ProfileMenu_License_Details_Label"
                  devFriendlyLabel="License Details"
                />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <FsLabel
                  textKey="ProfileMenu_About_Label"
                  devFriendlyLabel="About"
                />
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>
                  <FsLabel
                    textKey="ProfileMenu_User_Profile_Label"
                    devFriendlyLabel="User Profile"
                  />
                </NavLink>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Help</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink
                  replace
                  tag={Link}
                  className="text-dark"
                  to={logoutPath}
                  state={logoutState}
                >
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </NavItem>
        <FsAdminMenuModaly setCallables={this.setChildCallables} />
      </Fragment>
    );
  }

  anonymousView(registerPath, loginPath) {
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={registerPath}>
            Register
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} className="text-dark" to={loginPath}>
            Login
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }
}
