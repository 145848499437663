import { createSlice } from "@reduxjs/toolkit";

const langSlice = createSlice({
    name: 'currentLang',
    initialState: {
        currentLang: [],
    },
    reducers: {
        setCurrentLang(state, action) {
            state.currentLang = action.payload
        },
        getCurrentLang(state, action) {
            state.currentLang
        },
    }
});
export const actions = langSlice.actions;
export default langSlice;