import authService from './../components/api-authorization/AuthorizeService';
import { GET } from './HttpClientService'
//provides a sync method for getting apikey
export const getMapsApiKey = () => {
    return getMapKeyAsync();
}
const getMapKeyAsync = async () => {
    var res = await GET('api/maps/validate')
    if (res.success)
        return res.data;
    return '';
}
export const getAccountMapConfiguration = async () => {
    var email = await authService.getUserEmail();
    var res = await GET('api/maps/' + email);
    return res.data;
}
export const getAccountMapConfigurationUsingAccount = async (accountId) => {
    if (accountId) {
        var email = await authService.getUserEmail();
        var res = await GET(`api/maps/getMapConfig?email=${email}&accountId=${accountId}`);
        return res.data;
    }
    return null;
} 