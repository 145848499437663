import { createSlice } from "@reduxjs/toolkit";

const userOrgsSlice = createSlice({
    name: 'userOrgs',
    initialState: {
        userOrgs: [],
        currentOrg: null,
    },
    reducers: {
        setUserCurrentOrg(state, action) {
            state.currentOrg = action.payload
        },
        getUserCurrentOrg(state, action) {
            state.currentOrg
        },
        setUsersOrgs(state, action) {
            state.userOrgs = action.payload
        },
        getUserOrgs(state, action) {
            state.userOrgs
        }
    }
});
export const actions = userOrgsSlice.actions;
export default userOrgsSlice;