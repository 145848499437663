import React, { useEffect } from "react";
import MapProvier from "../mapProvier/mapProvier";
import "./fsapiMap.css";
import {
  getAccountMapConfiguration,
  getAccountMapConfigurationUsingAccount,
  getMapsApiKey,
} from "../../services/FsApiMapService";
import { UiUtilities } from "../uiUtilities/uiUtilities";
import { useSelector } from "react-redux";
import authService from "../api-authorization/AuthorizeService";
export const FsapiMap = () => {
  //Use this component to import all the data that derives
  //the maps component. The maps component will reside within
  //MapProvider class and can be changed as needed.
  //this approach will decouple the data derving the map
  //from the map component.

  //CRITICAL: it is critical that this component handles data communication
  //for getting markers and other map data items
  //CRITICAL it is critical that any map provider subscribes to the interface
  //provided by the FsAPIMap component
  const currentAccount = useSelector((state) => state.accounts.currentAccount);
  const [
    accountMapConfiguration,
    setAccountMapConfiguration,
  ] = React.useState();
  const [mapsKey, setMapKey] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const loadData = async () => {
    const selectUser = await authService.getProfile();
    let res = null;
    selectUser.fscAdmin
      ? (res = await getAccountMapConfigurationUsingAccount(
          currentAccount ? currentAccount.accountId ?? null : null
        ))
      : (res = await getAccountMapConfiguration());
    var key = getMapsApiKey();
    if (res) {
      setAccountMapConfiguration(res);
      setMapKey(key.data);
      setHasError(false);
    } else {
      setHasError(true);
      setErrorMessage("Error loading maps configuration for this account!");
    }
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    setLoading(true);
    setHasError(false);
    loadData().then(() => setLoading(false));
  }, [currentAccount]);
  return (
    <>
      {loading ? (
        <UiUtilities utilityName="loader" />
      ) : hasError ? (
        <div>
          <h4>{errorMessage}</h4>
        </div>
      ) : (
        <div className="fsapi-mapy">
          <MapProvier
            markers={accountMapConfiguration.markers}
            mapContainerStyle={accountMapConfiguration.mapContainerStyle}
            center={accountMapConfiguration.center}
            zoom={accountMapConfiguration.zoom}
            useClusteredMarkers={accountMapConfiguration.useClusteredMarkers}
            mapApiKey={mapsKey}
          />
        </div>
      )}
    </>
  );
};
