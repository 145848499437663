import React, { Suspense, useEffect, lazy } from "react";

import "./fsAdminMenuModal.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Navbar,
  Col,
  Row,
  Nav,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
} from "reactstrap";
import { FsMenu } from "../FsApiUi/FsMenu";
import { GetAdminMenuDefs } from "../../services/MenuService";
import { SideNav } from "../FsApiUi/SideNav";
import { FsIcon } from "../FsApiUi/FsIcon";
import { UiUtilities } from "../uiUtilities/uiUtilities";
// import { ProvisionAccount } from "../account/ProvisionAccount";
// import { MyAccounts } from "../account/MyAccounts";
// import { Organization } from "../organization/Organization";
// import { FsApiSite } from "../fsApiSite/FsApiSite";
// import { Ccu } from "../ccu/Ccu";
// import { Rtu } from "../rtu/Rtu";
// import { ActivationLevel } from "../activationLevel/activationLevel";
// import { Kmz } from "../kmz/kmz";
//import { Container, Nav } from "react-bootstrap";

export const FsAdminMenuModaly = (props) => {
  const ProvisionAccount = lazy(() => import("../account/ProvisionAccount"));
  const MyAccounts = lazy(() => import("../account/MyAccounts"));
  const Organization = lazy(() => import("../organization/Organization"));
  const FsApiSite = lazy(() => import("../fsApiSite/FsApiSite"));
  const Ccu = lazy(() => import("../ccu/Ccu"));
  const Rtu = lazy(() => import("../rtu/Rtu"));
  const ActivationLevel = lazy(() =>
    import("../activationLevel/activationLevel")
  );
  const Kmz = lazy(() => import("../kmz/kmz"));

  const [modal, setModal] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState([]);
  const [currentSubMenuItems, setCurrentSubMenuItems] = React.useState([]);
  const [topMenu, setTopMenu] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [currentTab, setCurrentTab] = React.useState("account");
  const toggle = () => {
    setModal(!modal);
  };

  const loadMenuItems = async () => {
    const items = await GetAdminMenuDefs();
    setMenuItems(items);
    currentSubMenuItems.length < 1 ? setSubMenuItems(items[0].submenu) : <></>;
    currentSubMenuItems.length < 1 ? setTopMenu([{ index: 0 }]) : <></>;
  };

  const setSubMenuItems = (items) => {
    setCurrentSubMenuItems(items);
  };

  const renderContent = () => {
    switch (currentTab) {
      case "Provision Account":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <ProvisionAccount />
          </Suspense>
        );
      case "My Accounts":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <MyAccounts />
          </Suspense>
        );
      case "Organization":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <Organization />
          </Suspense>
        );
      case "FSAPI Site":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <FsApiSite />
          </Suspense>
        );
      case "CCU":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <Ccu />
          </Suspense>
        );
      case "RTU":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <Rtu />
          </Suspense>
        );
      case "Activation Level":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <ActivationLevel />
          </Suspense>
        );
      case "KMZ Upload":
        return (
          <Suspense fallback={<UiUtilities utilityName="loader" />}>
            <Kmz />
          </Suspense>
        );
      default:
        return "Error Loading!";
    }
  };

  useEffect(() => {
    loadMenuItems();
    props.setCallables({
      doToggle: toggle,
    });
  }, []);
  return (
    <div className="fs-admin-menu-modaly">
      <div>
        <Modal isOpen={modal} toggle={toggle} className={props?.className}>
          <ModalBody>
            <div>
              {/* Navbar with Hamburger Icon */}
              <Navbar
                className="navbar-expand-sm border-bottom-0 bg-secondary navbar-toggleable-sm ng-white border-bottom box-shadow  col-12 mb-0 padding-0"
                light
                expand="md"
              >
                <NavbarToggler
                  onClick={toggleMenu}
                  className="navbar-toggler-custom"
                >
                  <FsIcon name="menu" /> {/* Custom icon */}
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {menuItems.map((item, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            onClick={() => {
                              setSubMenuItems(item.submenu);
                              setTopMenu([{ index: index }]);
                            }}
                            className={
                              (topMenu?.find((x) => x.index === index)
                                ? "nav-link side-nav-hover bg-danger text-white"
                                : "") + " text-white"
                            }
                          >
                            {item.name}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            <Row style={{}} className="mt-0">
              <Col lg={2} className="mt-0">
                <Row>
                  {/*Using nested grid to achieve 1.5 grid*/}
                  <Col lg={9}>
                    <SideNav
                      topMenuIndexChange={topMenu}
                      subMenuItems={currentSubMenuItems}
                      setParentTab={setCurrentTab}
                    />
                  </Col>
                </Row>{" "}
              </Col>
              <Col lg={9} className="mt-3">
                {renderContent()}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              <FsIcon name="close" style={{ fontSize: "8pt" }} />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};
