import { createSlice } from "@reduxjs/toolkit";

const userAccountsSlice = createSlice({
    name: 'userAccounts',
    initialState: {
        userAccounts: [],
        currentAccount: null,
    },
    reducers: {
        setUserCurrentAccount(state, action) {
            state.currentAccount = action.payload
        },
        getUserCurrentAccount(state, action) {
            state.currentAccount
        },
        setUsersAccounts(state, action) {
            state.userAccounts = action.payload
        },
        getUserAccounts(state, action) {
            state.userAccounts
        }
    }
});
export const actions = userAccountsSlice.actions;
export default userAccountsSlice;