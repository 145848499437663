import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FsIcon } from "./FsIcon";

export const SideNav = ({ subMenuItems, setParentTab, topMenuIndexChange }) => {
  const [selected, setSelected] = React.useState([]);
  const [navItemsNames, setNavItems] = React.useState([]);

  const setItem = (item, index) => {
    setSelected([{ item, index }]);
  };

  useEffect(() => {
    selectFirstItemandFirstParentOnNavigate();
    setItem(subMenuItems[0], 0);
  }, [subMenuItems, topMenuIndexChange]);

  useEffect(() => {
    selectFirstItemandFirstParentOnNavigate();
  }, []);

  const setParentTabPrivate = (val) => {
    setParentTab(val);
  };

  const selectFirstItemandFirstParentOnNavigate = () => {
    setNavItems(subMenuItems.map((x) => x.name));
    setParentTabPrivate(subMenuItems.map((x) => x.name)[0]);
  };

  return (
    <>
      {subMenuItems && subMenuItems.length > 0 ? (
        <Navbar expand="lg" bg="secondary">
          <Navbar.Toggle
            aria-controls="navbarNav"
            className="navbar-toggler-custom"
          >
            <FsIcon name="menu" /> {/* Custom icon */}
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarNav">
            <Nav className="flex-column width-100pct min-height-650">
              {subMenuItems.map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    href="#"
                    className={
                      selected.find((x) => x.index === index)
                        ? "nav-link side-nav-hover bg-danger text-white"
                        : "nav-link text-white "
                    }
                    onClick={() => {
                      setItem(item, index);
                      setParentTabPrivate(navItemsNames[index]);
                    }}
                  >
                    {item.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <></>
      )}
    </>
  );
};
