import React, { Component } from "react";
import { GET } from "./../services/HttpClientService";
import { FsButton } from "./FsApiUi/FsButton";
export class Counter extends Component {
  static displayName = Counter.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }
  async activate() {
    let res = await GET(
      "api/QlmApi/Activate?licenseKey=AMPD0-F0600-GHJ4K-F8N18-2V23G-BSKMCE99P&computerKey=36LT00911"
    );
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1,
    });
  }

  render() {
    return (
      <div>
        <h1>Counter</h1>

        <p>This is a simple example of a React component.</p>

        <p aria-live="polite">
          Current count: <strong>{this.state.currentCount}</strong>
        </p>
        <input type="text" />
        <FsButton className="btn btn-primary" onClick={this.activate}>
          Activate
        </FsButton>
        <FsButton className="btn btn-primary" onClick={this.incrementCounter}>
          Increment
        </FsButton>
      </div>
    );
  }
}
