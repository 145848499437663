
import React, { Fragment, useState } from "react"
import { useEffect } from "react";
import { useSelector } from "react-redux"

export const FsLabel = ({ textKey, toUpper = false, replaceIfEmpty = 'No Label Found' }) => {
    const currentLang = useSelector((state) => state.langs.currentLang);
    const [translation, setTranslation] = useState('');
    const getComputedValue = () => {
        if (currentLang) {
            var item = currentLang.find(x => x.key === textKey);
            if (item) {
                var value = item.value;
                if (!value) {
                    return replaceIfEmpty;
                }
                if (toUpper) {
                    return value.toUpperCase();
                }
                return value;
            }
        }
        return replaceIfEmpty;
    }
    useEffect(() => {
        if (currentLang) {
            setTranslation(getComputedValue());
        }
    }, [])
    useEffect(() => {
        if (currentLang) {
            setTimeout(() => { setTranslation(getComputedValue()) }, 500)
        }
    }, [currentLang])
    return <>
        {
            <Fragment>
                { translation }
            </Fragment>
        }
    </>
}
