import React, { Component } from "react";

export class FsIcon extends Component {
  render() {
    return (
      <span
        className={"material-symbols-outlined " + this.props.className ?? ""}
        style={this.props.style ?? {}}
      >
        {this.props.name}
      </span>
    );
  }
}
