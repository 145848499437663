import authService from "./../components/api-authorization/AuthorizeService";
import { DELETE, GET, POST, POST_WITH_FILE } from "./HttpClientService";

export const getUserAccounts$ = async () => {
  var isAuthenticated = await authService.isAuthenticated();
  if (isAuthenticated) {
    var email = await authService.getUserEmail();
    var res = await GET(`api/account/${email}`);
    if (res.success) return res.data;
  }
  return "";
};
export const createAccount$ = async (params) => {
  var email = await authService.getUserEmail();
  var res = await POST(`api/account/${email}`, JSON.stringify(params));
  return res.data;
};

export const performDeleteAccount = async (accountId) => {
  var res = await DELETE("api/account/" + accountId);
  return res;
};
export const getAccountConfiguration = async (accountId) => {
  var res = await GET(`api/account/Configuration/${accountId}`);
  return res.data;
};

export const postAccountConfiguration = async (file, params) => {
  var res = await POST_WITH_FILE(
    file,
    JSON.stringify(params),
    "api/account/PostConfig"
  );
  return res;
};

export const getKmzDetails = async (siteIds) => {
  var res = await GET(
    `api/account/GetKMZFiles?` + siteIds.map((id) => `siteIds=${id}`).join("&")
  );
  return res.data;
};
//UploadKmzFile
export const uploadKmzFile = async (file, params) => {
  //UpdateKMZDetails
  var res = await POST_WITH_FILE(
    file,
    JSON.stringify(params),
    "api/account/UploadKmzFile"
  );
  return res;
};

export const updateKMZDetails = async (file, params) => {
  //UpdateKMZDetails
  var res = await POST_WITH_FILE(
    file,
    JSON.stringify(params),
    "api/account/UpdateKMZDetails"
  );
  return res;
};
